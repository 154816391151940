/* pagenotfound.css */

.main-pagenot {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

}

.main-pagenot img {
    max-width: 100%;
    height: auto;
    background-color: blue;
}

.errpagebtn {
    align-items: center;
    text-align: center;
    justify-content: center;
}
.errpagebtn button{
    height: 2.5rem;
    width: 20rem;
    margin-bottom: 10rem;
    margin-top: 0;
    border: 5px solid rgb(230, 153, 20);
    border-radius: 25px;
    background-color: bisque;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;

}
.main-div {
    padding: 2rem;
}

.main-heading {
    position: relative;
    padding-top: 1.3rem;
}

.main-heading p {
    position: absolute;
    padding-right: 6rem;
    padding-top: 4px;
    font-weight: bold;
    cursor: pointer;
    font-size: 20px;
    margin-left: 0.3rem;
}

.main-heading h3 {
    border: 2px solid #13abe2;
    padding-left: 2rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #13abe2;
    ;
    font-size: x-large;
    font-weight: bold;
}

.answersof {
    font-weight: bold;
    font-size: 1rem;
    padding-left: 2.5rem;
    border-top: none;
    border: 2px solid blue;
}

.content-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.accordion-section {
    width: 50%;
}

.accordion-section img {
    max-width: 100%;
    height: auto;
    padding-right: 4rem;
}

@media only screen and (max-width: 768px) {
    .accordion-section {
        width: 100%;
    }

    .accordion-section img {
        padding-right: 0rem;
    }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


h2 {
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
}

input {
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
}
.OptionDropdown{
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
}

button {
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}

.login-container, .dashboard-container, .verify-container {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.certificates-list ul {
  list-style: none;
  padding: 0;
}

.certificates-list li {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
}

.certificates-list button {
  margin-left: 10px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}


.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

/* Responsive layout */
@media screen and (max-width: 600px) {
  th, td {
    padding: 6px;
    font-size: 12px;
  }
}


/* certificate verifiaction  css */
.result {
  padding: 1rem;
  margin: 1rem 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.result p {
  margin: 0.5rem 0;
}

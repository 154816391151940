.intern-halfback {
  /* background-image: url('path/to/your/image.jpg'); */
  background-image: url('https://img.freepik.com/free-vector/blue-background-with-abstract-waves_1393-258.jpg?size=626&ext=jpg&ga=GA1.1.553209589.1713225600&semt=ais');
  background-color: #075068;
  background-size: cover; /* Adjust as needed */
  background-position: center; /* Adjust as needed */
  background-repeat: no-repeat; /* Adjust as needed */
  margin: 0;
  height: auto;
}

.Offer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Offer button {
  margin-top: 8rem;
  height: 3rem;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 2px solid rgb(28, 157, 238);
  /* Border color */
  display: inline-block;
  /* Ensures button takes up only as much space as necessary */
  padding: 0 1rem;
  /* Adjust padding as needed */
  text-align: center;
  /* Center text horizontally */
  text-decoration: none;
  /* Removes underline by default */
  color: black;

}


.InterContenst-new {
  
  justify-content: center;
  align-items: center;
  margin: 2rem;
  margin-left: 12rem;
  margin-right: 12rem;
  font-size: 1.2rem;
  /* color: #f3e5e5; */
  color: black;
  padding-bottom: 2rem;

}
.child p{
  font-size: 1.1rem;
}

/* Media Query for mobile responsiveness */
@media screen and (max-width: 600px) {
 
.InterContenst-new{
  margin-left: 2rem;
  margin-right: 2rem;
  font-size: 1.2rem;
}
  .heading {
    font-size: 1rem;
    color: rgb(12, 152, 233);
  }
}


.main-box-sub {
  display: flex;
  flex-wrap: wrap;
  /* Allow items to wrap to the next row */
  margin: 1rem;

}

.main-box-sub :hover {
  background-color: #075068;
  color: white;
}

.child-new {
  flex: 1;
  min-width: 0;
  border: 1px solid #f3e5e5;
  margin: 10px;
  border-radius: 35px;
  padding: 1rem;
  box-sizing: border-box;
}



/* Define a keyframe animation for shaking */
@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateY(-5px);
  }

  50% {
    transform: translateX(5px);
  }

  75% {
    transform: translateX(-5px);
  }

  100% {
    transform: translateX(0);
  }
}

.image-class-new {
  display: flex;
  height: 8rem;
  /* Adjust the height as needed */
  align-items: center;
  justify-content: center;
  animation: shake 1.8s infinite;
  /* Apply the shaking animation */
}

.image-class-new img {
  max-width: 80%;
  /* Ensure the image doesn't exceed the container's width */
  max-height: 100%;
  /* Ensure the image doesn't exceed the container's height */
}

.offer-high-light {
  text-align: center;
  font-weight: bold;
  color: rgb(9, 140, 210);
}

.ApplyBtn{
  display: flex;
  align-items: center;
  justify-content: center;
}

          
.ApplyBtn button{
  margin: 1rem;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  border: 2px solid rgb(238, 147, 10);
  background-color: rgb(0, 129, 129);
  display: inline-block;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  color: white;
  font-size: large;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.ApplyBtn button:hover{
  background-color: black;
  animation: slideText 0.5s alternate infinite; 
}


@keyframes slideText {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-3px);
  }
  100% {
    transform: translateX(3px);
  }
}




/* Adjustments for responsiveness */
@media screen and (max-width: 768px) {
  .child-new {
    flex-basis: calc(100% - 10px);
    /* Calculate width for mobile view */
  }
 
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .child-new {
    flex-basis: calc(50% - 10px);
    /* Calculate width for iPad view */
  }
 
 
}
/* Media query for tablets (landscape) and smaller devices */
@media (max-width: 1024px) {
  .content-main-box {
      padding: 40px; /*Adjust padding for smaller screens*/
  }
}



.content-main-box-home {
  /* padding: 60px; */
  background-image: url("../img/homemain.jpg");
  background-size: cover; /* Ensure the background image covers the entire container */
  background-position: center; /* Center the background image */
  height: auto;
  display: flex;
  justify-content: left; /* Center horizontally */
  align-items: left; /* Center vertically */
  text-align: left;
}

.content-box-home {
  max-width: 850px; /* Adjust the maximum width as needed */
  padding: 25px; /* Add padding */
  margin: 2rem 3.5rem 11rem 10rem;
}


.content-box-home p{
  text-align: left;
  font-size: 1.5rem;
    /* Apply gradient color */
    background: linear-gradient(to right, rgb(38, 34, 30),rgb(12, 166, 227));
    -webkit-background-clip: text; /* For Safari */
    background-clip: text;
    color: transparent; /* Hide original text color */
    font-weight:900;
  
}


.OurSlogoan {
  /* margin-top: 1.5rem; */
  margin-bottom: 4rem;
  font-size: 1.2rem;
  color: rgb(15, 16, 18);
  font-weight: bolder;
  text-align: left;
}

/* .slogantitle {
  margin-top: 0rem;
  text-align: center;
  font-weight: bolder;
  font-size: 3rem;
  color: #13abe2;
  font-family: serif;
} */

/* Apply animation to .slogantitle */
.slogantitle {
  margin-top: 0rem;
  text-align: center;
  font-weight: bolder;
  font-size: 4rem;
  color: #13abe2; 
  font-family: serif;
  animation: colorChange 5s infinite alternate; /* Apply color changing animation */
}

@keyframes colorChange {
  0% { color: rgb(255, 0, 0); } /* Start color (red) */
  25% { color: rgb(3, 69, 93); } /* Start color (red) */
  50% { color: rgb(4, 189, 193); } /* Middle color (green) */
  75% { color: rgb(190, 55, 227); } /* Middle color (green) */
  100% { color: rgb(230, 16, 84); } /* End color (blue) */
}

.know-more-btn {
  display: inline-block;
  display: flex;
  padding: 10px 20px;
  background-color: #007bff; /* Button background color */
  color: #ffffff; /* Text color */
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: serif; 
}

.know-more-btn:hover {
  background-color: #03031a; /* Button background color on hover */
}



@media screen and (min-width: 768px) {
  .content-main-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .content-box {
    width: 45%;
    margin-bottom: 0;
  }

  .aboutusimages {
    max-width: 100%;
  }
}

@media screen and (max-width:768px) {
  .Internshipbtn button {
    height: 2.5rem;
    width: 100%;
    
  }

  .content-box-home p{
    
    
  }
  .content-box-home p{
    font-size: 1.1rem;
  }
  .content-box-home {
    margin: 0.8rem;
  }

  .second-wrap {
    margin-top: -6rem;
  }

  .content-main-box {
    padding: 20px;
  }

  .slogantitle{
    font-size: 2.2rem;
    letter-spacing:0dvb;
  }
}










/*What we off div  */
.whatweofferclss{
  margin-top: 3rem;
}


/* Our Supports */
.Our-Supports-main-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

}

.databox {
  text-align: center;
  padding: 20px;
  flex: 1;
  border: 2px saddlebrown !important;
}

.databox i {
  font-size: 3rem;
  color: #13abe2;
}

.databox p {
  font-weight: 500;
}



.main-new-contect {
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    padding: 2rem;

  }
  .main-new-contect{
    overflow-x: hidden;
  }
  body{
    overflow-x: hidden;
  }
  
  .content-wrapper {
    display: flex; /* Use flex to arrange content and image in a row */
    justify-content: space-around; /* Add space between content and image */
    align-items: center; /* Center items vertically */
    width: 100%; /* Make sure the content takes full width */
    max-width: 1200px; /* Limit the maximum width for larger screens */
    margin: 0 auto; /* Center content horizontally */
  }
  
  .content {
    flex: 1; /* Allow content to grow and fill space */
    padding: 20px; /* Add padding for spacing */
    color: white;
  }

  .content h2{
    font-size: 5rem;
  }
  
  .image {
    flex: 1; /* Allow image to grow and fill space */
  }
  
  img {
    max-width: 85%; 
    height: auto;
  }
  
  /* Responsive styles */
  @media (max-width: 800px) {
    .content-wrapper {
      flex-direction: column;
    }
  
    .content,
    .image {
        
      flex: none; 
      width: 100%;
      margin: 0;
      
    }
    .content h2 {
      font-size: 2rem;
      display: flex;
      justify-content:center;
      padding: 0;
      margin-top: -1rem; 
      margin-bottom: 2rem; 
    }
    
    .content p {
      margin-top: 1rem; 
      font-size: large;
    }  
  }

  /* New Section Code */
 
/* AboutUs.css */
.company-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem;
}

.company-info-c {
  width: 100%;
  text-align: center;
  padding: 2rem;
}

.img-c {
  width: 100%;
  margin-top: 2rem;
  position: relative;
  left: 0; /* Reset left position */
}

.company-info-c span {
  font-size: 5rem;
  color: #13abe2;
  margin-top: 2rem;
  text-align: left;
  
}

.company-info-c p{
  text-align: left;
  justify-content: left; /* Center the span */
}

.explore-btn {
  height: 2.8rem;
  width: 16rem;
  background-color: #13abe2;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}
.explore-btn:hover{
  background-color: #4ec5f1;
}

.explore-btn i {
  margin-left: 5px;
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
}



.explore-btn:hover i {
  transform: translateX(20px);
}


@media (min-width: 600px) {
  .company-c {
    flex-direction: row;
    justify-content: space-between;
    
  }

  .company-info-c {
    width: 50%;
    text-align: left;
    padding: 2rem;
  }

  .img-c {
    width: 50%;
    margin-top: 0;
  }

  .company-info-c span {
    left: auto; /* Reset left position */
    top: auto; /* Reset top position */
    margin-top: 0; /* Reset margin */
    text-align: left; /* Align the span text to the left */
  }
  
}

.social-div {
    display: flex;
    justify-content: center;
}

.social-div a {
    /* display: inline-block; */
    background-color: black;
    padding: 10px;
    margin: 8px;
    font-size: 30px;
    border-radius: 8px; /* Rounded shape */
    transition: background-color 0.3s; /* Smooth transition on hover */
}

.social-div a:hover {
    background-color: #2f2b2b; /* Darker shade of black on hover */
}

.social-div a svg {
    color: white; /* Icon color */
    width: 25px; /* Adjust icon size */
    height: 25px; /* Adjust icon size */
}

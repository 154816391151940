.company {
  display: flex;
  flex-direction: column;
  gap: 0px;
  /* Spacing between child elements */
  padding: 1.3rem;
}

.company-img img {
  max-width: 100%;
  height: auto;

}

.company-info {
  text-align: left;
  margin: 2rem;
}

.company-info p{
  font-size: 1.1rem;
}

.testdemp{
  align-items: center;
  justify-content: center;
  text-align: center;
}
.missonandvissoncontent{
  font-size: 1.1rem;
}

/* mission and vision */

.content-main-box-about {
  display: flex;
  flex-direction: row;
  margin: 2rem;
}

.content-box-about {
  margin: 2rem; 
}
.testdemp{
  align-items: center;
  justify-content: center;
  text-align: center;
}
.missonandvissoncontent{
  font-size: 1.2rem;
}


@media (max-width: 768px) {
  .content-main-box-about {
      flex-direction: column; /* Stack children vertically */
  }
}




/* founder section */
.founder-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(to top, #cee3f1 0%, #e2ebf0 100%);
}

.founder-image {
  margin-top: 2rem;
  width: 200px; /* Set a fixed width for the circular image */
  height: 200px; /* Set a fixed height for the circular image */
  border-radius: 50%; /* Make the image circular */
  overflow: hidden; /* Hide any content outside the circular shape */   
}

.founder-image img {
  max-width: 100%; /* Ensure the image scales down to fit the container */
  height: auto; /* Maintain the image's aspect ratio */
}

.founder-details {
  max-width: 800px; /* Set a maximum width for the content */
  box-sizing: border-box;
  text-align: left;
  padding: 1.7rem;
}
   

@media (min-width: 768px) {
  .company {
    flex-direction: row;
    align-items: center;
    text-align: left;
  }

  .company-img {
    flex: 1;
  }

  .company-info {
    flex: 2;
    text-align: left;
    margin: 2rem;
  }

  .founder-container {
    flex-direction: column;
    justify-content: space-around;
  }

}
.company {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    /* background-color: blue; */
}

.company-info {
    width: 100%;
    text-align: center;
   
}

.img {
    width: 100%;
    margin-top: 0rem;
    position: relative;
    left: 0;
    /* Reset left position */
}



.company-info p {
    text-align: left;
    justify-content: left;
    /* Center the span */
}


@media (min-width: 600px) {
    .company {
        flex-direction: row;
        justify-content: space-between;

    }

    .company-info {
        width: 50%;
        text-align: left;
        padding: 2rem;
    }

    .img {
        width: 50%;
        margin-top: 0;
    }

    .company-info span {
        left: auto;
        /* Reset left position */
        top: auto;
        /* Reset top position */
        margin-top: 0;
        /* Reset margin */
        text-align: left;
        /* Align the span text to the left */
    }

}

body{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    overflow-x: hidden;
}


 



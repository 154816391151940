.text-tags{
  text-decoration: none;
  color: yellow;
}
.col a{
  text-decoration: none;
  color: white;
  
}
.row a{
  text-decoration: none;
  color: white;
}
.nav-links a.active {
    font-weight: bold;
	/* color: #eb1303; */
	color: #13abe2;
	text-decoration: underline;
  }
.logo {
	font-size: 1.5rem;
	font-weight: bold;

}

.logo img {
	height: 68px;
	width: 85px;
	border-radius: 8px;
}


.nav-links :hover {
	color: rgb(44, 232, 178);
	cursor: pointer;
}


* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "Titillium Web", sans-serif;
}

:root {
	--mainColor: #29335c;
	--mainColorLight: #5767aa;
	--secondaryColor: #db2b39;
	--textColor: #eee;
}

header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 80px;
	padding: 0 2rem;
	background: linear-gradient(to top, #09203f 0%, #537895 100%);
	color: var(--textColor);
}

nav a {
	margin: 0 1rem;
	color: white;
	font-weight: bolder;
	font-size: 1.3rem;
	text-decoration: none;
	align-items: left;
	justify-content: space-between;
}

nav a:hover {
	color: rgb(14, 231, 191);
}

header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

header div,
nav {
	display: flex;
	align-items: center;
}



@media only screen and (max-width: 1024px) {
	header .nav-btn {
		visibility: visible;
		opacity: 1;
	}

	header nav {
		position: fixed;
		top: -100vh;
		left: 0;
		height: auto;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 0.3rem;
		/* background-color: rgb(225, 240, 165); */
		background: linear-gradient(to top, #09203f 0%, #537895 100%);
		transition: 1s;
	}

	header .responsive_nav {
		transform: translateY(100vh);
	}

	nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
		color: #db2b39;
		cursor: pointer;
	}

	nav .nav-close-btn :hover {
		color: #0de4c7;
		cursor: pointer;
	}

	nav a {
		font-size: 1.2rem;
	}
}
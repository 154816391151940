.main-box {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next row */
    margin: 1rem;
   
  }

  .main-box :hover{
    background-color: #075068;
    color: white;
  }
  
  .child {
    flex: 1; /* Each child takes up an equal portion of the available space */
    min-width: 0; /* Ensure child elements can shrink if necessary */
    border: 1px solid #f3e5e5;
    margin: 10px;
    border-radius: 35px;
    padding: 1rem;
    box-sizing: border-box;
  }


  .image-class{
    display: flex;
    height: 8rem; /* Adjust the height as needed */
    align-items: center;
    justify-content: center;

  }

  .image-class img {
    max-width: 100%; /* Ensure the image doesn't exceed the container's width */
    max-height: 100%; /* Ensure the image doesn't exceed the container's height */
  }

  .offer-high-light{
    text-align: center;
    font-weight: bold;
    color: rgb(9, 140, 210);
  }
  .desc{
    font-size: 1.1rem;
  }
  /* Adjustments for responsiveness */
  @media screen and (max-width: 768px) {
    .child {
      flex-basis: calc(100% - 10px); /* Calculate width for mobile view */
    }
  }
  
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .child {
      flex-basis: calc(50% - 10px); /* Calculate width for iPad view */
    }
  }
  